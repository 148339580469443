<template>
	<div class="view view-files" :class="$route.name">
		<!-- <div class="background" :data-category="logged_user.role.category"></div> -->

		<div class="side">
			<div class="scroll" v-scrollbar>
				<div>
					<files-menu />
				</div>
			</div>
		</div>

		<div v-if="$route.name === 'files-category'" class="content" :class="$route.params.category">
			<div v-if="episodes.length" class="tabs">
				<hooper :settings="slider_settings" class="slider" ref="carousel" @slide="slide" @updated="slider_updated">
					<slide v-for="episode in episodes" :key="episode" :class="{ active: episode === current_episode }" v-hammer:tap="() => set_episode(episode)" class="tab">Episode {{ (episode + 1) }}</slide>
				</hooper>
				<div v-if="prev_visible" class="tab-arrow prev" v-hammer:tap="slide_prev"><</div>
				<div v-if="next_visible" class="tab-arrow next" v-hammer:tap="slide_next">></div>
			</div>
			<div class="scroll has-tabs" v-scrollbar>
				<div>
					<component :is="content_type" :type="$route.params.category" :episode="current_episode" />
				</div>
			</div>
			<ui-link to="/game/files" class="close close-file-reader"><ui-icon name="close-blue-dark" /></ui-link>
		</div>

		<div v-if="$route.name === 'file-viewer'" class="content">
			<file-viewer v-if="$route.params.category === 'images' || $route.params.category === 'inventory'" />
			<div class="scroll" v-scrollbar>
				<div>
					<file-reader v-if="$route.params.category === 'documents'" />
					<file-memory v-if="$route.params.category === 'memories'" />
				</div>
			</div>
			<ui-link :to="'/game/files/' + $route.params.category" class="close close-file-viewer"><ui-icon name="close-blue-dark" /></ui-link>
		</div>

		<div v-if="$route.name === 'files-notes'" class="content">
			<div class="scroll" v-scrollbar>
				<div>
					<files-notes />
				</div>
			</div>
			<ui-link to="/game/files" class="close close-file-reader"><ui-icon name="close-blue-dark" /></ui-link>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import FilesMenu from '@/components/game/files/files-menu'
import FileReader from '@/components/game/files/file-reader'
import FileMemory from '@/components/game/files/file-memory'
import FileViewer from '@/components/game/files/file-viewer'
import FilesList from '@/components/game/files/files-list'
import FilesNotes from '@/components/game/files/files-notes'
import { Hooper, Slide } from 'hooper'
import 'hooper/dist/hooper.css'

export default {
	name: 'view-files',
	data() {
		return {
			slider_buttons_visible: false,
			current_slide: 0,
			current_episode: null,
			slider_settings: {
				trimWhiteSpace: true,
				mouseDrag: false,
				itemsToShow: 3,
				breakpoints: {
					570: {
						itemsToShow: 4,
					},
					670: {
						itemsToShow: 5,
					}
				}
			}
		}
	},
	computed: {
		...mapGetters([ 'logged_user', 'game', 'files' ]),
		episodes() {
			// return [ 6, 5, 4, 3, 2, 1, 0 ]
			return [ ...new Set(
				[ ...this.files.documents, ...this.files.images, ...this.files.inventory, ...this.files.memories ].filter(d => d.in_game_day_created >= 0).map(d => d.in_game_day_created))
			].sort().reverse()
		},
		content_type() {
			if (this.$route.params.file_id) return 'files-ready'
			return 'files-list'
		},
		prev_visible() {
			if (this.slider_buttons_visible) {
				return this.current_slide > 0
			}
			return false
		},
		next_visible() {
			if (this.slider_buttons_visible) {
				return this.current_slide + this.$refs.carousel.config.itemsToShow < this.episodes.length
			}
			return false
		},
	},
	methods: {
		set_episode(episode) {
			this.current_episode = episode
		},
		slider_updated() {
			if (this.episodes.length > this.$refs.carousel.config.itemsToShow) {
				this.slider_buttons_visible = true
			} else
				this.slider_buttons_visible = false
		},
		slide(e) {
			this.current_slide = e.currentSlide
		},
		slide_prev() {
			this.$refs.carousel.slidePrev()
		},
		slide_next() {
			this.$refs.carousel.slideNext()
		},
	},
	created() {
		if (this.episodes.length) this.set_episode(this.episodes[0])
	},
	components: { FilesMenu, FileReader, FileMemory, FileViewer, FilesList, FilesNotes, Hooper, Slide }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/variables'


#app.mobile, #app.tablet-vertical, #app.tablet-horizontal, #app.ipad-theme
	.view-files
		&.files-category, &.file-viewer, &.files-notes
			.side
				display none !important
		.side, .content
			max-width none
		.side
			margin 0
		.content
			.tabs
				margin 0 40px
				width calc(100% - 80px)
			.close
				display flex
				&.close-file-reader
					// left auto
					// bottom auto
					// right 32px
					// top 32px
					background var(--color-close-button-bg)
					flex center center
					color var(--color-close-button-text)

#app.ipad-theme
	.view-files
		.content
			.tabs
				margin 0 24px

// #app.mobile
// 	.view-files
// 		.content
// 			.close
// 				&.close-file-reader
// 					right 16px
// 					top 16px
					

.view-files
	display block

.side
	float left
	height 100%
	width 100%
	max-width 415px
	margin 0 40px 0 16px
.content
	float left
	height 100%
	width 100%
	max-width 600px
	.tabs
		width 100%
		height 64px
		padding 16px 0 0 0
		border-bottom 4px solid var(--color-files-tab-active-bg)
		font-size 1.6rem
		.slider
			height 48px
			outline none
			.tab
				display inline-block
				width 120px
				height 48px
				line-height @height
				white-space nowrap
				text-align center
				cursor pointer
				opacity 0.5
				&:hover
					opacity 1
				&.active
					background-color var(--color-files-tab-active-bg)
					color var(--color-files-tab-active-text)
					border-top-left-radius 4px
					border-top-right-radius 4px
					cursor default
					opacity 1
		.tab-arrow
			position absolute
			top calc(50% - 8px)
			height 32px
			width 32px
			flex center center
			background-color var(--color-files-tab-active-bg)
			color var(--color-files-tab-active-text)
			border-radius 50%
			font-size 2.4rem
			cursor pointer
			// box-shadow 0 0 0 2px var(--color-bg)
			&.prev
				left -34px
			&.next
				right -34px
			&:active
				transform translateY(1px)
	.scroll.has-tabs
		height calc(100% - 64px)
	.close
		display none
		position absolute
		left calc(50% - 24px)
		bottom 24px
		width 48px
		height 48px
		border-radius 50%
		background var(--color-close-button-bg)
		flex center center
		color var(--color-close-button-text)
		box-shadow 0 2px 4px alpha(#000, 50%)
		cursor pointer
		flex center center
		&.close-file-reader
			display none
		&.close-file-viewer
			display flex
			left auto
			bottom auto
			right 32px
			top 32px
			background var(--color-image-viewer-close-bg)
			color var(--color-image-viewer-close-text)
			
.background
	position absolute
	left 50%
	top 50%
	transform translate(-50%, -50%)
	width 75%
	height 75%
	background center center no-repeat
	background-size contain
	&[data-category="Law Enforcement"]
		background-image url(../../assets/img/emblems/law-enforcement.svg)
	&[data-category="Citizens"]
		background-image url(../../assets/img/emblems/citizens.svg)
	&[data-category="Government"]
		background-image url(../../assets/img/emblems/government.svg)
	&[data-category="Media"]
		background-image url(../../assets/img/emblems/media.svg)
	
</style>
