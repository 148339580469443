<template>
	<div class="file-viewer">
		<div class="file-viewer-content">
			
			<hooper class="slider-main" group="group" ref="carousel" @slide="change_slide">
				<slide v-for="media in images" :key="media.id" :id="'file-' + media.id">
					<div class="img" v-fancybox="{ src: media.url || media.data }" data-gallery="file-viewer" v-bg="media"></div>
					<div v-if="media.sender" class="image-infos">From {{ media.sender.name }}</div>
				</slide>
			</hooper>

			<div class="separator"></div>
			
			<hooper class="slider-bullets" group="group" :itemsToShow="nb_bullets" :centerMode="true">
				<slide v-for="(media, i) in images" :key="media.id" v-hammer:tap="() => slideTo(i)">
					<div class="img" v-bg="media"></div>
				</slide>
				<!-- <hooper-navigation slot="hooper-addons"></hooper-navigation> -->
			</hooper>

			<div class="arrow left" v-hammer:tap="() => prev()"></div>
			<div class="arrow right" v-hammer:tap="() => next()"></div>

		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'
import 'hooper/dist/hooper.css'
import Apollo from '@/apollo/apollo-client'

export default {
	name: 'file-viewer',
	data() {
		return {
			lastSlideId: null
		}
	},
	computed: {
		...mapGetters([ 'app', 'files', 'logged_user' ]),
		images() {
			return this.files[this.$route.params.category]
		},
		nb_bullets() {
			if (this.app.device == 'mobile') return 4
			if (this.app.device == 'tablet') return 5
			return 6
		}
	},
	methods: {
		change_slide(e) {
			const image = this.images[e.currentSlide]
			this.$store.dispatch('read_image', { image })
			if (this.lastSlideId !== image.id) {
				Apollo.mutate('READ_PLAYER_FILE', { file_id: parseInt(image.id), player_id: parseInt(this.logged_user.id) })
				this.lastSlideId = image.id
			}
		},
		slideTo(n) {
			this.$refs.carousel.slideTo(n)
		},
		prev() {
			this.$refs.carousel.slidePrev()
		},
		next() {
			this.$refs.carousel.slideNext()
		},
	},
	mounted() {
		if (this.$route.params.file_id) {
			let slide = this.$el.querySelector('#file-' + this.$route.params.file_id)
			if (slide) {
				let index = [].indexOf.call(slide.parentElement.children, slide)
				if (index > -1) this.slideTo(index) //this.$refs.carousel.slideTo(index)
			}
		}
	},
	components: { Hooper, Slide, HooperNavigation }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


#app.mobile
	.file-viewer
		padding 16px 0 0 0
		.file-viewer-content
			padding 0
			>>> .hooper
				.hooper-navigation
					.hooper-prev
						left 8px
					.hooper-next
						right 8px


.file-viewer
	height calc(100% - 16px)
	margin 8px
	background-color var(--color-image-viewer-bg)
	user-select none
	// padding 72px 0
	padding 16px 0 0 0
	.file-viewer-content
		padding 0 40px
		height 100%
		.slider-main
			height calc(100% - 180px)
			.hooper-slide
				padding 0 16px
				// > img
				// 	object-fit contain
				.img
					width 100%
					height 100%
					background center center no-repeat
					background-size contain
					cursor zoom-in
				.image-infos
					position absolute
					left 0
					bottom 0
					right 0
					padding 8px 0
					background-color var(--color-image-viewer-bg)
					text-align center
		.separator
			height 18px
			margin 16px 0
			border-top 2px solid var(--color-image-viewer-border)
			&:after
				content ''
				position absolute
				left calc(50% - 10px)
				top 0
				width 0
				height 0
				border-style solid
				border-width 10px 10px 0 10px
				border-color var(--color-image-viewer-border) transparent transparent transparent
		.slider-bullets
			height 100px
			.hooper-slide
				text-align center
				padding 0 4px
				cursor pointer
				> img
					object-fit cover
		.arrow
			position absolute
			bottom 30px
			width 40px
			height 100px
			cursor pointer
			user-select none
			flex center center
			opacity 0.5
			&:hover
				opacity 1
			&.left
				left 0
				&:before
					content ''
					width 0
					height 0
					border-style solid
					border-width 12px 16px 12px 0
					border-color transparent var(--color-image-viewer-border) transparent transparent
			&.right
				right 0
				&:before
					content ''
					width 0
					height 0
					border-style solid
					border-width 12px 0 12px 16px
					border-color transparent transparent transparent var(--color-image-viewer-border)


>>> .hooper-slide
	text-align center
	// > img
	// 	height 100%
	// 	width 100%
	// 	margin 0 auto
	.img
		width 100%
		height 100%
		background center center no-repeat
		background-size cover
		
			
>>> .hooper
	outline none
	.hooper-navigation
		position absolute
		left 0
		top 50%
		right 0
		height 0
		.hooper-prev, .hooper-next
			padding 0
			&:before
				content ''
				position absolute
				top 0
				width 0
				height 0
				border-style solid
				padding 0
			&.hooper-prev
				&:before
					left 8px
					border-width 12px 16px 12px 0
					border-color transparent var(--color-image-viewer-border) transparent transparent
			&.hooper-next
				&:before
					right 8px
					border-width 12px 0 12px 16px
					border-color transparent transparent transparent var(--color-image-viewer-border)
			svg
				opacity 0

</style>
