<template>
	<div class="files-notes">
		<div class="head">
			Your notes
		</div>
		<div v-show="!text" class="placeholder">Your text here</div>
		<div class="input" ref="text" @input="set_text" contenteditable></div>
		<div v-if="loading" class="loading"><ui-icon name="button-loader" :size="64" /></div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apollo from '@/apollo/apollo-client'

function insertTextAtCursor(text) {
	let sel, range
	if (window.getSelection) {
		sel = window.getSelection()
		if (sel.getRangeAt && sel.rangeCount) {
			range = sel.getRangeAt(0)
			range.deleteContents()
			range.insertNode(document.createTextNode(text))
		}
	} else if (document.selection && document.selection.createRange) {
		document.selection.createRange().text = text
	}
}

function debounce(callback, delay){
	var timer;
	return function(){
		var args = arguments;
		var context = this;
		clearTimeout(timer);
		timer = setTimeout(function(){
			callback.apply(context, args);
		}, delay)
	}
}


export default {
	name: 'files-notes',
	data() {
		return {
			note_id: null,
			loading: true,
			text: ''
		}
	},
	computed: {
		...mapGetters([ 'app', 'logged_user' ]),
	},
	methods: {
		// focus(e) {
		// 	if (this.text === 'Your text here') {
		// 		this.text = ''
		// 		this.$refs.text.innerText = this.text
		// 	}
		// },
		set_text(e) {
			this.text = this.$refs.text.innerText
			if (this.text === '\n') this.text = ''
			this.save()
		},
		save: debounce(function() {
			if (this.note_id) {
				Apollo.mutate('UPDATE_PLAYER_NOTE', { note_id: parseInt(this.note_id), data: this.text })
			} else {
				Apollo.mutate('ADD_PLAYER_NOTE', { player_id: parseInt(this.logged_user.id), data: this.text })
			}
		}, 300)
	},
	async mounted() {
		// let default_text = ''
		const notes = await Apollo.query('GET_PLAYER_NOTES', { player_id: this.logged_user.id })
		if (notes && notes.length > 0 && notes[0].id) {
			this.note_id = parseInt(notes[0].id)
			if (notes[0].data) this.text = notes[0].data
		}
		else {
			this.note_id = await Apollo.mutate('ADD_PLAYER_NOTE', { player_id: parseInt(this.logged_user.id), data: this.text })
		}

		this.$refs.text.innerText = this.text

		this.$refs.text.addEventListener('paste', (e) => {
			e.preventDefault()
			if (e.clipboardData && e.clipboardData.getData) {
				let text = e.clipboardData.getData("text/plain")
				document.execCommand("insertHTML", false, text)
			} else if (window.clipboardData && window.clipboardData.getData) {
				let text = window.clipboardData.getData("Text")
				insertTextAtCursor(text)
			}
		})

		this.loading = false
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


#app.mobile, #app.tablet-vertical, #app.tablet-horizontal, #app.ipad-theme
	.files-notes
		margin 0
		.head
			border-bottom none
			>>> .main-title
				display block
				margin 0
				color var(--color-notes-area-text)

#app.mobile
	.files-notes
		padding 16px
		.placeholder
			left 16px
			top 72px
		.input
			min-height calc(100vh - 205px)

.files-notes
	margin 32px 0
	padding 32px
	background-color var(--color-notes-area-bg)
	color var(--color-notes-area-text)
	font-size 1.6rem
	.head
		padding 0 0 16px 0
		margin 0 0 16px 0
		border-bottom 2px solid var(--color-notes-area-border)
		text-transform uppercase
	.placeholder
		position absolute
		left 32px
		top 91px
		color var(--color-notes-area-text)
		pointer-events none
		opacity 0.5
	.input
		min-height calc(100vh - 268px)
		outline none
	
	.loading
		absolute 0
		background-color var(--color-notes-area-bg)
		flex center center
	


</style>
