<template>
	<div class="files-menu">
		<div class="menu">
			<main-title icon="menu/menu-files" text="Files" />
			
			<ui-link v-if="game.scenario.enable_file" to="/game/files/documents" class="item" :class="{ loading: files.loading, current: $route.params.category === 'documents' }">
				<ui-icon class="icon" name="files/files-folder" :size="40" />
				Documents
				<span v-if="nb_new_documents > 0" class="new">{{ nb_new_documents }}</span>
				<ui-link v-show="!$route.params.file_id" to="/game/files" class="close"><ui-icon name="close-blue-dark" :size="16" /></ui-link>
			</ui-link>

			<ui-link v-if="game.scenario.enable_image" to="/game/files/images" class="item" :class="{ loading: files.loading, current: $route.params.category === 'images' }">
				<ui-icon class="icon" name="files/files-my-media" :size="40" />
				Images
				<span v-if="nb_new_images > 0" class="new">{{ nb_new_images }}</span>
				<ui-link v-show="!$route.params.file_id" to="/game/files" class="close"><ui-icon name="close-blue-dark" :size="16" /></ui-link>
			</ui-link>

			<ui-link v-if="game.scenario.enable_inventory" to="/game/files/inventory" class="item" :class="{ loading: files.loading, current: $route.params.category === 'inventory' }">
				<ui-icon class="icon" name="files/files-inventory" :size="40" />
				Inventory
				<span v-if="nb_new_inventory > 0" class="new">{{ nb_new_inventory }}</span>
				<ui-link v-show="!$route.params.file_id" to="/game/inventory" class="close"><ui-icon name="close-blue-dark" :size="16" /></ui-link>
			</ui-link>

			<div class="separator"></div>

			<ui-link to="/game/files/notes" class="item" :class="{ loading: files.loading, current: $route.name === 'files-notes' }">
				<ui-icon class="icon" name="menu/menu-notes" :size="40" />
				Notes
				<ui-link v-show="$route.name === 'files-notes'" to="/game/files" class="close"><ui-icon name="close-blue-dark" :size="16" /></ui-link>
			</ui-link>

			<!-- <div class="separator"></div>

			<ui-link to="/game/files/memories" class="item" :class="{ loading: files.loading, current: $route.params.category === 'memories' }">
				<ui-icon class="icon" name="files/files-brain" :size="40" />
				Memories
				<span v-if="nb_new_memories > 0" class="new">{{ nb_new_memories }}</span>
				<ui-link v-show="!$route.params.file_id" to="/game/files" class="close"></ui-link>
			</ui-link> -->

		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import MainTitle from '@/components/game/main-title'

export default {
	name: 'files-menu',
	data() {
		return {
			
		}
	},
	computed: {
		...mapGetters([ 'app', 'game', 'files' ]),
		nb_new_documents() {
			return this.files.documents.filter((doc) => { return doc && !doc.read }).length
		},
		nb_new_images() {
			return this.files.images.filter((image) => { return image && !image.read }).length
		},
		nb_new_memories() {
			return this.files.memories.filter((memory) => { return memory && !memory.read }).length
		},
		nb_new_inventory() {
			return this.files.inventory.filter((inventory) => { return inventory && !inventory.read }).length
		}
	},
	components: { MainTitle }
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


#app
	&.mobile
		.files-menu
			.menu
				.item
					width 100%
					&.exact-active
						transform none
						.close
							display none
	&.tablet-vertical, &.tablet-horizontal, &.ipad-theme
		.files-menu
			padding 32px
			.menu
				padding 96px 0 0 0
				flex space-between flex-start
				flex-wrap wrap
				>>> .main-title
					position absolute
					left 0
					top 0
					right 0
				.item
					width calc(50% - 8px)
					margin 0 0 16px 0
					flex center center
					flex-direction column
					height 96px
					.icon
						margin 0 0 8px 0
					.new
						left calc(50% + 8px)
	&.tablet-horizontal
		.files-menu
			.menu
				flex flex-start center
				.item
					width calc(33.3333% - 16px)
					margin 0 16px 16px 0
					height 120px
					.new
						top 20px


.files-menu
	width 100%
	padding 16px
	height 100%
	font-family 'Josefin Sans', sans-serif
	.menu
		.item
			width 90%
			height 64px
			flex flex-start center
			padding 0 24px
			margin 0 0 8px 0
			background-color var(--color-files-item-bg)
			border-radius 8px
			cursor pointer
			text-transform uppercase
			font-weight 600
			transition transform 0.25s easeOutQuart
			font-size 2rem
			color var(--color-files-item-text)
			// &.title
			// 	background-color transparent
			// 	padding-left 16px
			// 	font-weight 400
			// 	font-size 2.5rem
			// 	color blue_lighter
			// 	.icon
			// 		margin-right 16px
			// 		background-color blue_lighter
			&.current
				transform translateX(16px)
				&.loading
					background-image url(../../../assets/img/loader.svg)
					background-repeat no-repeat
					background-position right 16px center
					background-size 64px auto
				.close
					display flex
			.icon
				width 40px
				height 40px
				margin 0 24px 0 0
			.new
				position absolute
				left -16px
				top calc(50% - 16px)
				width 32px
				height 32px
				padding-top 2px
				flex center center
				border-radius 50%
				background-color red_notif
				text-align center
				color var(--color-files-item-text)
			.close
				flex center center
				display none
				position absolute
				right -20px
				top calc(50% - 20px)
				width 40px
				height 40px
				border-radius 50%
				background var(--color-files-item-close-bg)
				color var(--color-files-item-close-text)
				background-size 16px auto
				box-shadow 0 0 0 4px var(--color-bg)
				transition transform 0.1s easeOutQuart
				&:hover
					transform scale(1.05)
				&:active
					transform scale(0.95)
		.separator
			margin 16px 0
			height 2px
			border-radius 2px
			width calc(100% - 40px)
			background-color blue_lighter
</style>
