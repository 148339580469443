<template>
	<div class="files-list" :class="{ 'has-infos': infos_visible }">
		
		<div v-if="$route.params.category === 'documents'" class="files">
			<ui-link v-for="doc in documents" :to="'/game/files/documents/' + doc.id" :key="doc.id" class="file-item" :class="doc.file_type">
				<div class="document">
					<div class="title">{{ doc.file_type | file_type_to_str }}</div>
					<div class="content">
						<div class="line">
							<div class="label">Subject :</div>
							<!-- <div class="value">North Korea's Naval exercise in the northern japanese sea.</div> -->
							<div class="value">{{ doc.title }}</div>
						</div>
						<div v-if="doc.from" class="line">
							<div class="label">From :</div>
							<div class="value">{{ doc.from }}</div>
						</div>
					</div>
					<div v-if="!doc.read" class="new">new</div>
					<div class="time">{{ ago(doc.in_game_created) }}</div>
				</div>
			</ui-link>
		</div>
		
		<div v-if="$route.params.category === 'memories'" class="files">
			<ui-link v-for="memory in documents" :to="'/game/files/memories/' + memory.id" :key="memory.id" class="file-item">
				<div class="memory">
					<div class="title">Memory</div>
					<div class="content">
						<div class="picture"></div>
						<div class="name">{{ memory.title }}</div>
					</div>
					<div v-if="!memory.read" class="new">new</div>
				</div>
			</ui-link>
		</div>

		<div v-if="$route.params.category === 'images'" class="files">
			<ui-link v-for="media in documents" :to="'/game/files/images/' + media.id" class="file-item" :key="media.id">
				<div class="image">
					<div class="picture">
						<div class="img" v-bg="media"></div>
					</div>
					<div v-if="infos_visible && media.sender && media.sender.name" class="infos">
						<p class="from">from</p>
						<p class="name">{{ media.sender.name }}</p>
					</div>
					<div v-if="!media.read" class="new">new</div>
				</div>
			</ui-link>
		</div>

		<div v-if="$route.params.category === 'inventory'" class="files">
			<ui-link v-for="media in documents" :to="'/game/files/inventory/' + media.id" class="file-item" :key="media.id">
				<div class="image">
					<div class="picture">
						<div class="img" v-bg="media"></div>
					</div>
					<div v-if="infos_visible && media.sender && media.sender.name" class="infos">
						<p class="from">from</p>
						<p class="name">{{ media.sender.name }}</p>
					</div>
					<div v-if="!media.read" class="new">new</div>
				</div>
			</ui-link>
		</div>

	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import formatDistance from 'date-fns/formatDistance'

export default {
	name: 'files-list',
	props: {
		type: { default: null },
		episode: { default: null },
	},
	computed: {
		...mapGetters([ 'app', 'files', 'game' ]),
		infos_visible() {
			if (this.$route.params.category === 'received') return true
			return false
		},
		documents() {
			let documents = []
			if (this.$route.params.category === 'documents') documents = this.files.documents
			if (this.$route.params.category === 'memories') documents = this.files.memories
			if (this.$route.params.category === 'inventory') documents = this.files.inventory
			if (this.$route.params.category === 'images') documents = this.files[this.type]

			documents = documents.filter(d => d.in_game_day_created === this.episode)

			return documents
		}
	},
	filters: {
		file_type_to_str(value) {
			if (value === 'confidential') return "Confidential"
			if (value === 'secret') return "secret"
			if (value === 'top-secret') return "Top secret"
			if (value === 'memo') return "Memo"
			return value
		},
	},
	methods: {
		ago(time) {
			return formatDistance(new Date(parseInt(time) * 1000), new Date((this.game.room.timer_total - this.game.room.timer) * 1000), { addSuffix: true })
		}
	}
}
</script>

<style lang="stylus" scoped>
@import '../../../assets/css/variables'


#app.not-touch
	.files-list
		.files
			.file-item
				transition 0.1s easeOutQuart
				&:hover
					transform translateY(-2px)
					opacity 0.8
				&:active
					transform translateY(2px)
					opacity 1


#app.mobile, #app.tablet-vertical, #app.tablet-horizontal, #app.ipad-theme
	.files-list
		.files
			padding 16px
			.file-item
				width calc(50% - 16px)
				margin 8px 8px 8px 8px
				.picture
					height 120px
// #app.mobile
// 	.files-list
// 		.files
// 			padding-top 80px
	
#app.tablet-vertical
	.files-list
		.files
			.file-item
				width calc(33.3333% - 16px)
				margin 8px 8px 8px 8px

#app.tablet-horizontal
	.files-list
		.files
			.file-item
				width calc(25% - 16px)
				margin 8px 8px 8px 8px


.files-list
	width 100%
	height 100%
	&.has-infos
		.files
			.file-item
				.picture
					height 114px
	.files
		flex flex-start flex-start
		flex-wrap wrap
		padding 32px 0
		.file-item
			width 183px
			padding 8px
			margin 0 16px 16px 0
			background-color var(--color-files-item-bg)
			cursor pointer
			&.memo
				background-color var(--color-doc-memo)
				.document
					.title, .line .label
						color var(--color-doc-memo)
						border-color var(--color-doc-memo)
				.time
					background-color var(--color-doc-memo)
					color #fff
			&.confidential
				background-color var(--color-doc-confidential)
				.document
					.title, .line .label
						color var(--color-doc-confidential)
						border-color var(--color-doc-confidential)
				.time
					background-color var(--color-doc-confidential)
					color #fff
			&.secret
				background-color var(--color-doc-secret)
				.document
					.title, .line .label
						color var(--color-doc-secret)
						border-color var(--color-doc-secret)
				.time
					background-color var(--color-doc-secret)
					color #fff
			&.top-secret
				background-color var(--color-doc-top-secret)
				.document
					.title, .line .label
						color var(--color-doc-top-secret)
						border-color var(--color-doc-top-secret)
				.time
					background-color var(--color-doc-top-secret)
					color #fff
			&:nth-child(3n)
				margin-right 0
			.time
				position absolute
				left -6px
				bottom -6px
				padding 4px 10px
				font-size 1.15rem
				line-height 1.2em
			.image
				.picture
					width 100%
					height 167px
					overflow hidden
					// img
					// 	width 100%
					// 	height 100%
					// 	object-fit cover
					.img
						width 100%
						height 100%
						background center center no-repeat
						background-size cover
				.infos
					padding 12px 0 4px 0
					line-height 16px
					color #E2F6FF
					.from
						text-transform uppercase
					.name
						font-size 1.2rem
			.document
				height 167px
				padding 8px
				background-color #fff
				text-align left
				color dark
				.title
					margin 0 0 4px 0
					border-bottom 1px solid red
					text-align center
					font-weight 700
					line-height 24px
					white-space nowrap
					text-transform uppercase
					font-size 1.8rem
				.content
					height calc(100% - 28px)
					flex space-between flex-start
					flex-direction column
					.line
						width 100%
						.label
							font-size 1.2rem
						.value
							width 100%
							text-align center
							font-size 1.2rem
							line-height 16px
			.memory
				height 167px
				padding 8px
				background-color #fff
				text-align left
				color dark
				.title
					margin 0 0 4px 0
					border-bottom 1px solid blue
					text-align center
					font-weight 700
					line-height 24px
					white-space nowrap
					text-transform uppercase
					color blue
					font-size 1.8rem
				.picture
					height 80px
					margin 8px 0 0
					background-color #C0BCC3
				.name
					flex center center
					height 32px
					margin 8px 0 0 0
					font-size 1.4rem
					line-height 16px
					text-align center
			.new
				position absolute
				right -16px
				top -16px
				height 24px
				padding 1px 8px 0 8px
				border-radius 32px
				background-color red_notif
				color #fff




</style>
